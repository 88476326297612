import React, { useEffect, useState } from 'react'
import TextCard from '~/components/molecules/TextCard'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'
import parse from 'html-react-parser';
import Image from '../atoms/Image';
import TextLink from '../atoms/TextLink';

function CertificateTemplate({ key, index, postcodeParameter, ...props }) {
    const section = props.paymentsTenantsTemplate
    //console.log(section.containersLogoText)

    return (
        <section id={section.fieldGroupName + index} className=" pt-24 bg-sand relative sm:mb-24">
            <div className="container pb-9">
                <FadeInWhenVisible className="flex flex-col xxl:items-center">
                    <TextCard className="text-left " {...section.textCard} image={false} customAlign={true} headingClassName="text-center w-full md:w-35/5 lg:w-4/6 xl:w-25/4 xxl:w-3/5 mx-auto mb-8" contentDesign="text-center sm:w-4/6 sm:mx-auto" />
                </FadeInWhenVisible>
            </div>
            <div className='container xl:max-w-none bg-white pt-24'>
                <FadeInWhenVisible className="flex flex-col xxl:items-center">
                    {parse(section.simpletext)}
                </FadeInWhenVisible>
            </div>
            {(section.containersLogoText[0].containerText) || (section.textTitle)
                ?
                    <FadeInWhenVisible className="flex flex-col xxl:items-center bg-white pt-24" transition={{ delay: '.15' }}>
                        <div className='text-h1-small text-navy mb-28 container'>{section.textTitle}</div>
                        <div className='flex flex-col xl:flex-row xl:items-center justify-around xxl:w-full'>
                            {section.containersLogoText[0].containerText && <div className='flex flex-col bg-seafoam w-full sm:w-4/5 xl:max-w-xl sm:self-center mt-8' style={{minHeight: '320px'}}>
                                <div className='mx-auto relative -top-20 h-24'>
                                    <Image data={section.containersLogoText[0].containerLogo} className={`w-56`} objectFit="contain" objectPosition="center" />
                                </div>
                                <div className={`text-body-large text-navy text-center w-4/5 self-center mb-8` }>
                                    {section.containersLogoText[0].containerText}
                                </div>
                                <TextLink {...section.containersLogoText[0].linkButton} className="mb-16 w-2/4 md:w-fit-content self-center text-center mt-auto" />
                            </div>}
                            {section.containersLogoText[1].containerText && <div className='flex flex-col bg-rose w-full mt-40 sm:w-4/5 xl:max-w-xl sm:self-center xl:mt-8' style={{minHeight: '320px'}}>
                                <div className='mx-auto relative -top-20 h-24'>
                                    <Image data={section.containersLogoText[1].containerLogo} className={`w-56`} objectFit="contain" objectPosition="center" />
                                </div>
                                <div className={`text-body-large text-navy text-center w-4/5 self-center mb-8` }>
                                    {section.containersLogoText[1].containerText}
                                </div>
                                <TextLink {...section.containersLogoText[1].linkButton} className="mb-16 w-2/4 md:w-fit-content self-center text-center mt-auto" />
                            </div>}
                        </div>
                    </FadeInWhenVisible>
                :
                    <div className='mb-28'></div>
            }
        </section>
    )
}

export default CertificateTemplate